import { PricingValue, Product, ProductPriceRange, ProductVariant } from 'etc/storefront-types'

export const getRangePriceString = (range: ProductPriceRange) => {
	const same = range.maxVariantPrice.amount === range.minVariantPrice.amount

	const price = formatPriceString(range.minVariantPrice.amount)

	return `${same ? '' : 'ab '}${price}`
}

export const formatPriceString = (amount: number | string | PricingValue) => {
	const string = new Intl.NumberFormat('de', {
		style: 'currency',
		currency: 'EUR'
	}).format(typeof amount === 'number' ? amount : parseFloat(amount as string))

	return string
}

export const getComparedPrices = (compareAt: ProductPriceRange, price: ProductPriceRange) => {
	const minComparePrice = parseFloat(compareAt.minVariantPrice.amount)
	const minPrice = parseFloat(price.minVariantPrice.amount)
	const maxPrice = parseFloat(price.maxVariantPrice.amount)
	const sale = minComparePrice > minPrice

	return {
		minComparePrice,
		minPrice,
		maxPrice,
		sale
	}
}

export const getVariantUnitPrice = (variant: ProductVariant) => {
	const litresEdge = variant.metafields.find(metafield => metafield?.key === 'product_unit_litre')
	const litres = litresEdge ? parseFloat(litresEdge.value) : null

	const showKgPrice = variant.metafields.find(
		metafield => metafield?.key === 'product_show_kg_price'
	)?.value

	const price = parseFloat(variant.priceV2.amount)

	if (litres) {
		return {
			unit: 'l',
			price: price / litres
		}
	} else if (showKgPrice && variant.weight) {
		return {
			unit: 'kg',
			price: price / variant.weight
		}
	} else {
		return null
	}
}

export const getProductUnitPrice = (product: Product) => {
	let unitPrice: { unit: string; price: number } | null = null
	let priceString = ''

	for (let variant of product.variants.edges) {
		const price = getVariantUnitPrice(variant.node)
		if (price) {
			if (!unitPrice) {
				unitPrice = price
				priceString = `${formatPriceString(unitPrice.price)}/${unitPrice.unit}`
			} else if (price.unit === unitPrice.unit && price.price < unitPrice.price) {
				unitPrice = price
				priceString = `ab ${formatPriceString(unitPrice.price)}/${unitPrice.unit}`
			}
		}
	}

	return unitPrice ? priceString : null
}
