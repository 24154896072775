import { gql } from '@apollo/client'

const ProductPreview = gql`
	fragment ProductPreview on Product {
		title
		handle
		vendor
		createdAt
		priceRange {
			minVariantPrice {
				amount
			}
			maxVariantPrice {
				amount
			}
		}
		compareAtPriceRange {
			minVariantPrice {
				amount
			}
			maxVariantPrice {
				amount
			}
		}
		images(first: 2) {
			edges {
				node {
					transformedSrc(maxWidth: 400)
				}
			}
		}
		variants(first: 100) {
			edges {
				node {
					priceV2 {
						amount
					}
					weight
					metafields(
						identifiers: [
							{ namespace: "global", key: "product_unit_litre" }
							{ namespace: "global", key: "product_show_kg_price" }
						]
					) {
						key
						value
					}
				}
			}
		}
	}
`

export const COLLECTIONS_QUERY = gql`
	query ($query: String!) {
		collections(query: $query, first: 20) {
			edges {
				node {
					handle
					products(first: 17) {
						edges {
							node {
								...ProductPreview
							}
						}
					}
				}
			}
		}
	}
	${ProductPreview}
`

export const COLLECTION_PRODUCTS = gql`
	query ($collection: String!, $first: Int = 250) {
		collectionByHandle(handle: $collection) {
			products(first: $first) {
				edges {
					node {
						...ProductPreview
					}
				}
			}
		}
	}
	${ProductPreview}
`

export const PRODUCT_INFO = gql`
	query ($handle: String!) {
		productByHandle(handle: $handle) {
			id
			handle
			title
			description
			descriptionHtml
			vendor
			images(first: 100) {
				edges {
					node {
						full: transformedSrc(maxWidth: 1400)
						medium: transformedSrc(maxWidth: 900)
						thumbnail: transformedSrc(maxWidth: 150)
					}
				}
			}
			tags
			options {
				id
				name
				values
			}
			metafields(
				identifiers: [
					{ namespace: "global", key: "fee_category" }
					{ namespace: "global", key: "blog_article" }
					{ namespace: "global", key: "coupon" }
					{ namespace: "global", key: "estimated_delivery_days" }
					{ namespace: "global", key: "estimated_delivery_weeks" }
					{ namespace: "global", key: "is_alcohol" }
					{ namespace: "global", key: "product_accordion_content" }
					{ namespace: "global", key: "product_featured_info" }
					{ namespace: "global", key: "region" }
				]
			) {
				key
				value
			}
			variants(first: 100) {
				edges {
					node {
						id
						quantityAvailable
						availableForSale
						priceV2 {
							amount
							currencyCode
						}
						compareAtPriceV2 {
							amount
							currencyCode
						}
						selectedOptions {
							name
							value
						}
						image {
							full: transformedSrc(maxWidth: 1400)
							medium: transformedSrc(maxWidth: 900)
							thumbnail: transformedSrc(maxWidth: 150)
						}
						weight
						metafields(
							identifiers: [
								{ namespace: "global", key: "product_unit_litre" }
								{ namespace: "global", key: "product_show_kg_price" }
							]
						) {
							key
							value
						}
					}
				}
			}
		}
	}
`

export const PRODUCT_SPOTLIGHT = gql`
	query ($handle: String!) {
		productByHandle(handle: $handle) {
			id
			handle
			title
			availableForSale
			descriptionHtml
			vendor
			images(first: 1) {
				edges {
					node {
						id
						transformedSrc
					}
				}
			}
			priceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
			compareAtPriceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
			metafields(
				identifiers: [
					{ namespace: "global", key: "fee_category" }
					{ namespace: "global", key: "blog_article" }
					{ namespace: "global", key: "coupon" }
					{ namespace: "global", key: "estimated_delivery_days" }
					{ namespace: "global", key: "estimated_delivery_weeks" }
					{ namespace: "global", key: "is_alcohol" }
					{ namespace: "global", key: "product_accordion_content" }
					{ namespace: "global", key: "product_featured_info" }
					{ namespace: "global", key: "region" }
				]
			) {
				key
				value
			}
			variants(first: 100) {
				edges {
					node {
						priceV2 {
							amount
						}
						weight
						metafields(
							identifiers: [
								{ namespace: "global", key: "product_unit_litre" }
								{ namespace: "global", key: "product_show_kg_price" }
							]
						) {
							key
							value
						}
					}
				}
			}
		}
	}
`

export const PRODUCT_SEARCH_QUERY = gql`
	query ($query: String!) {
		products(query: $query, first: 250) {
			edges {
				node {
					...ProductPreview
				}
			}
		}
	}
	${ProductPreview}
`

export const HIGHTLIGHT_PRODUCTS = gql`
	query ($first: String!, $second: String!, $third: String!) {
		first: productByHandle(handle: $first) {
			...ProductPreview
		}
		second: productByHandle(handle: $second) {
			...ProductPreview
		}
		third: productByHandle(handle: $third) {
			...ProductPreview
		}
	}
	${ProductPreview}
`

export const BESTSELLING_PRODUCTS = gql`
	query {
		products(query: "available_for_sale:true", sortKey: BEST_SELLING, first: 20) {
			edges {
				node {
					...ProductPreview
				}
			}
		}
	}
	${ProductPreview}
`

const PartialCheckout = gql`
	fragment PartialCheckout on Checkout {
		id
		webUrl
		totalPriceV2 {
			amount
		}
		lineItemsSubtotalPrice {
			amount
		}
		discountApplications(first: 20) {
			edges {
				node {
					value {
						... on MoneyV2 {
							amount
						}
					}
				}
			}
		}
	}
`

export const CREATE_CHECKOUT = gql`
	mutation ($lineItems: [CheckoutLineItemInput!]) {
		checkoutCreate(input: { lineItems: $lineItems }) {
			checkout {
				...PartialCheckout
			}
		}
	}
	${PartialCheckout}
`

export const UPDATE_CHECKOUT = gql`
	mutation checkoutLineItemsReplace($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
		checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
			checkout {
				...PartialCheckout
			}
		}
	}
	${PartialCheckout}
`

export const APPLY_DISCOUNT_CODE = gql`
	mutation checkoutDiscountCodeApply($discountCode: String!, $checkoutId: ID!) {
		checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
			checkout {
				...PartialCheckout
			}
		}
	}
	${PartialCheckout}
`

export const REMOVE_DISCOUNT_CODE = gql`
	mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
		checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
			checkout {
				...PartialCheckout
			}
		}
	}
	${PartialCheckout}
`

export const ORDER_BY_CHECKOUT = gql`
	query ($id: ID!) {
		node(id: $id) {
			... on Checkout {
				order {
					id
					name
					email
					totalPriceV2 {
						amount
					}
					shippingAddress {
						formatted(withName: true)
					}
					lineItems(first: 250) {
						edges {
							node {
								title
								quantity
								discountedTotalPrice {
									amount
								}
								variant {
									selectedOptions {
										name
										value
									}
									sku
									product {
										handle
										vendor
										images(first: 1) {
											edges {
												node {
													transformedSrc(maxWidth: 100)
												}
											}
										}
										metafields(
											identifiers: [
												{ namespace: "global", key: "fee_category" }
												{ namespace: "global", key: "blog_article" }
												{ namespace: "global", key: "coupon" }
												{ namespace: "global", key: "estimated_delivery_days" }
												{ namespace: "global", key: "estimated_delivery_weeks" }
												{ namespace: "global", key: "is_alcohol" }
												{ namespace: "global", key: "product_accordion_content" }
												{ namespace: "global", key: "product_featured_info" }
												{ namespace: "global", key: "region" }
											]
										) {
											key
											value
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const PRODUCT_HANDLES = gql`
	{
		products(first: 250) {
			edges {
				cursor
				node {
					handle
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
`

export const PRODUCT_HANDLES_NEXT = gql`
	query ($after: String!) {
		products(first: 250, after: $after) {
			edges {
				cursor
				node {
					handle
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
`

export const FAQS = gql`
	{
		faqs {
			title
			icon {
				url
			}
			slug
			questions {
				title
				anchor
			}
		}
	}
`

export const FAQS_DATA = gql`
	{
		faqs {
			title
			icon {
				url
			}
			slug
			questions {
				title
				anchor
				content
			}
		}
	}
`

export const VENDOR_PAGE = gql`
	query ($vendor: String!) {
		vendors(where: { slug: $vendor }) {
			slug
			name
			title
			profession
			region
			logo {
				full {
					url
				}
				small {
					url
				}
				light {
					url
				}
			}
			image {
				url
			}
			color {
				background
				backgroundShade
				accent
				accentShade
			}
			minimumOrderValue
			pages {
				title
				slug
				modules {
					__typename
					... on ComponentModulesContent {
						title
						subtitle
						text
						image {
							url
						}
						link {
							name
							href
						}
						imageAlignLeft
						color {
							scheme
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesRichtext {
						content
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesVideo {
						youtube
						title
						subtitle
						text
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesImage {
						image {
							url
						}
						title
						subtitle
						text
						link {
							name
							href
						}
						color {
							scheme
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesGallery {
						images {
							url
						}
						title
						subtitle
						text
						layout
						imageAlignLeft
						color {
							scheme
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesProduct {
						handle
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesTeam {
						title
						members {
							name
							title
							text
							photo {
								url
							}
						}
						dark
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesCatalog {
						category
						type
						title
						link {
							name
							href
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesFaq {
						questions {
							question
							answer
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesGoogleReviews {
						elfsightClass
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesFacebookFeed {
						elfsightClass
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesInstagramFeed {
						elfsightClass
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesMap {
						iframeSrc
						address
						hours
						phone
						website
						social {
							facebook
							instagram
							youtube
							linkedin
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesTabs {
						items {
							title
							content
						}
						title
						subtitle
						text
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesNavigation {
						title
						text
						link {
							name
							href
						}
						image {
							url
						}
						variant
						color {
							scheme
						}
						margins {
							top
							bottom
						}
					}
					... on ComponentModulesBlog {
						article_category {
							id
						}
					}
					... on ComponentModulesBlogPreview {
						article_category {
							id
						}
						title
						margins {
							top
							bottom
						}
					}
				}
			}
		}
	}
`

export const VENDOR_SLUGS = gql`
	{
		vendors {
			slug
		}
	}
`

export const PRODUCT_PAGE_VENDOR_INFO = gql`
	query ($slug: String!) {
		vendors(where: { slug: $slug }) {
			slug
			name
			minimumOrderValue
			introText
			banner {
				title
				subtitle
				text
				image {
					url
				}
				link {
					name
					href
				}
				color {
					scheme
				}
				margins {
					top
					bottom
				}
				linkedArticleCategory {
					id
				}
			}
			color {
				background
				backgroundShade
				accent
				accentShade
			}
			logo {
				small {
					url
				}
			}
		}
	}
`

export const VENDOR_SLIDER = gql`
	{
		vendors {
			slug
			name
			profession
			logo {
				full {
					url
				}
			}
			image {
				url
			}
		}
	}
`

export const VENDORS = gql`
	{
		vendors {
			slug
			name
			minimumOrderValue
		}
	}
`

export const VENDOR_CONTACT = gql`
	query ($slug: String!) {
		vendors(where: { slug: $slug }) {
			name
			email {
				contact
			}
		}
	}
`

export const CATEGORIES = gql`
	{
		categories(where: { primary: true }) {
			...CategoryFragment
			items {
				...CategoryFragment
				items {
					...CategoryFragment
					items {
						...CategoryFragment
						items {
							...CategoryFragment
						}
					}
				}
			}
		}
	}

	fragment CategoryFragment on Category {
		handle
		name
		image {
			url
		}
		icon {
			url
		}
		banner {
			title
			content
			light
			accentColor
			backgroundColor
			image {
				url
			}
			overlay {
				url
			}
			icon {
				url
			}
		}
	}
`

export const FACES = gql`
	{
		faces {
			name
			image {
				url
				formats
			}
		}
	}
`

const ArticlePreview = gql`
	fragment ArticlePreview on Article {
		title
		image {
			url
		}
		slug
		readingTime
		published_at
		excerpt
		categories {
			name
			color
		}
		author {
			name
		}
	}
`

export const BLOG_ARTICLES = gql`
	{
		articlesCategories: articles {
			categories {
				id
				name
				color
			}
		}
		featured: articles(limit: 3, where: { categories_contains: 2 }, sort: "published_at:desc") {
			...ArticlePreview
		}
		latest: articles(limit: 3, sort: "published_at:desc") {
			...ArticlePreview
		}
		articles(sort: "published_at:desc") {
			...ArticlePreview
		}
	}
	${ArticlePreview}
`

export const LATEST_BLOG_ARTICLES = gql`
	{
		articles(limit: 3, sort: "published_at:desc") {
			...ArticlePreview
		}
	}
	${ArticlePreview}
`

export const BLOG_ARTICLES_SLUGS = gql`
	{
		articles {
			slug
		}
	}
`

export const BLOG_ARTICLE_PREVIEW_BY_SLUG = gql`
	query ($slug: String!) {
		articles(where: { slug: $slug }) {
			...ArticlePreview
		}
	}
	${ArticlePreview}
`

export const BLOG_ARTICLE_BY_SLUG = gql`
	query ($slug: String!) {
		articlesCategories: articles {
			categories {
				id
				name
				color
			}
		}
		featured: articles(limit: 3, where: { categories_contains: 2 }, sort: "published_at:desc") {
			...ArticlePreview
		}
		latest: articles(limit: 3, sort: "published_at:desc", where: { slug_ne: $slug }) {
			...ArticlePreview
		}
		articles(where: { slug: $slug }) {
			title
			image {
				url
			}
			slug
			categories {
				id
				name
				color
			}
			published_at
			author {
				name
				photo {
					url
				}
			}
			readingTime
			content {
				__typename
				... on ComponentModulesRichtext {
					content
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesImage {
					image {
						url
					}
					title
					subtitle
					text
					link {
						name
						href
					}
					color {
						scheme
					}
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesTabs {
					items {
						title
						content
					}
					title
					subtitle
					text
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesVideo {
					youtube
					title
					subtitle
					text
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesProduct {
					handle
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesGallery {
					images {
						url
					}
					title
					subtitle
					text
					layout
					imageAlignLeft
					color {
						scheme
					}
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesCatalog {
					category
					title
					type
					link {
						name
						href
					}
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesContent {
					title
					subtitle
					text
					image {
						url
					}
					link {
						name
						href
					}
					color {
						scheme
					}
					imageAlignLeft
					margins {
						top
						bottom
					}
				}
				... on ComponentModulesNavigation {
					title
					text
					link {
						name
						href
					}
					image {
						url
					}
					variant
					margins {
						top
						bottom
					}
				}
			}
		}
	}
	${ArticlePreview}
`

export const BLOG_CATEGORIES = gql`
	{
		articleCategories {
			id
		}
	}
`

export const BLOG_ARTICLES_BY_CATEGORY = gql`
	query ($id: ID!) {
		articlesCategories: articles {
			categories {
				id
				name
				color
			}
		}
		featured: articles(limit: 3, where: { categories_contains: 2 }, sort: "published_at:desc") {
			...ArticlePreview
		}
		latest: articles(limit: 3, sort: "published_at:desc") {
			...ArticlePreview
		}
		articles(where: { categories_contains: $id }, sort: "published_at:desc") {
			...ArticlePreview
		}
		articleCategory(id: $id) {
			name
			color
		}
	}
	${ArticlePreview}
`
