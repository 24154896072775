import styles from './background-wrapper.module.less'

interface Props {
	children: React.ReactNode
	color?: string
	className?: string
}

const BackgroundWrapper = ({ color, className, children }: Props) => (
	<div className={`${styles.wrapper} ${className}`} style={{ backgroundColor: color }}>
		{children}
	</div>
)

export default BackgroundWrapper
